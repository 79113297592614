import React from 'react'
import classes from './LoadingSpinner.module.css'


const LoadingSpinner = props => {
        return <div className={classes.Ldsring}><div></div><div></div><div></div><div></div></div>;
    
}

export default LoadingSpinner;
